import Head from "next/head";

import Navbar from "../components/Navbar";

import styles from "../styles/404.module.css";

export default function Custom404() {
  return (
    <div className={styles.root}>
      <Head>
        <title>Page Not Found | Yoyo Shortcut</title>
        <meta
          name="description"
          content="Fast shortcuts to your favorite sites"
        />
        <link rel="icon" href="/logo.svg" />
      </Head>

      <Navbar />

      <div className={styles.content}>404 | Page Not Found</div>
    </div>
  );
}
